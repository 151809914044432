<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports" />
      </v-col>
    </v-row>
    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.02)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.productTypeId"
                outlined
                placeholder="Product Type"
                item-value="id"
                item-text="name"
                return-object
                :items="productTypes"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                :items="venueServices"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                outlined
                placeholder="Cashier Name"
                v-model="searchParams.cashier_id"
                item-value="id"
                item-text="name"
                :items="casherNames"
                :loading="isLoading"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="2">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="190px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="190px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2Formatted"
                    outlined
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="1">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>

          <div class="md-card md-theme-default shadow-0 rounded-5">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">Cashier Report</v-col>

                  <v-col md="4"> </v-col>

                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission($modules.reports.cashier.slug)
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div class="md-content md-table md-theme-default table_borders">
                  <div
                    v-resize="onResize"
                    :style="{ height: `${containerHeight}px` }"
                  >
                    <table class="table header-fixed">
                      <thead class="sticky">
                        <tr>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Timestamp</div>
                            </div>
                          </th>

                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Cashier Name
                              </div>
                            </div>
                          </th>

                          <th
                            class="md-table-head sticky"
                            v-for="method in paymentMethods"
                            :key="method.id"
                          >
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                <span v-if="method.name != 'Amex'">
                                  {{ method.name }}
                                </span>
                                <span v-else>
                                  {{ method.name }}
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        mdi-information
                                      </v-icon>
                                    </template>
                                    <span>American Express</span>
                                  </v-tooltip>
                                </span>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head sticky">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Total</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody v-if="cashierReport.length">
                        <template v-for="(cash, index) in cashierReport">
                          <!--                        <tr-->
                          <!--                            :set="(total = {})"-->
                          <!--                            :key="`obj_head_${index}`"-->
                          <!--                            class="head_bg"-->
                          <!--                            v-if="cash.type == 'date'"-->
                          <!--                        >-->
                          <!--                          <td :colspan="paymentMethods.length + 2">-->
                          <!--                            <div-->
                          <!--                                class="-->
                          <!--                                  md-table-cell-container-->
                          <!--                                  text-center-->
                          <!--                                  font-weight-black-->
                          <!--                                "-->
                          <!--                            >-->
                          <!--                              Bills for Date:-->
                          <!--                              {{ cash.order_date | reportDateformat }}-->
                          <!--                            </div>-->
                          <!--                          </td>-->
                          <!--                        </tr>-->
                          <tr v-if="cash.type == 'data'" :key="`cash_${index}`">
                            <td class="md-table-cell">
                              <div class="text-center">
                                {{ cash.order_date | reportDateformat }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{
                                  cash.transaction_type == "count"
                                    ? "Count"
                                    : cash.cashier
                                    ? cash.cashier
                                    : "Online"
                                }}
                                {{
                                  cash.transaction_type == "refund"
                                    ? " (Refund)"
                                    : ""
                                }}
                              </div>
                            </td>
                            <template v-for="method in paymentMethods">
                              <td class="md-table-cell" :key="`${method.id}_m`">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  <span v-if="cash.transaction_type != 'count'">
                                    <span
                                      v-if="
                                        method.name == 'Online' &&
                                        cash.transaction_type != 'refund'
                                      "
                                    >
                                      {{ 0 | toCurrency }}
                                    </span>
                                    <span v-else>
                                      {{
                                        (cash[method.name.replace(" ", "")]
                                          ? parseFloat(
                                              cash[method.name.replace(" ", "")]
                                            )
                                          : 0) | toCurrency
                                      }}
                                    </span></span
                                  >
                                  <span v-else>
                                    <span
                                      v-if="
                                        method.name == 'Online' &&
                                        cash.type != 'refund'
                                      "
                                    >
                                      {{ 0 }}
                                    </span>

                                    <span v-else>
                                      {{
                                        cash[method.name.replace(" ", "")]
                                          ? parseFloat(
                                              cash[method.name.replace(" ", "")]
                                            )
                                          : 0
                                      }}</span
                                    ></span
                                  >
                                </div>
                              </td>
                            </template>

                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ getTypeTotal(cash) | toCurrency }}
                              </div>
                            </td>
                          </tr>

                          <tr
                            class="md-table-row tr-rounded font-weight-black"
                            style="
                              background: rgb(115, 139, 161);
                              color: rgb(255, 255, 255);
                            "
                            :key="`order_${index}`"
                            v-else-if="cash.type == 'total'"
                          >
                            <td></td>
                            <td class="md-table-cell" :set="(typesTotal = 0)">
                              <div class="md-table-cell-container text-center">
                                {{ cash.cashier }}
                              </div>
                            </td>
                            <template v-for="method in paymentMethods">
                              <td class="md-table-cell" :key="`${method.id}_m`">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  <span>
                                    {{
                                      (cash[method.name.replace(" ", "")]
                                        ? parseFloat(
                                            cash[method.name.replace(" ", "")]
                                          )
                                        : 0) | toCurrency
                                    }}
                                  </span>
                                </div>
                              </td>
                            </template>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ getTypeTotalTotal(cash) | toCurrency }}
                              </div>
                            </td>
                          </tr>
                          <tr
                            class="md-table-row grand_total_bg"
                            :key="`order_${index}`"
                            v-else-if="cash.type == 'refund'"
                          >
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ cash.cashier }} (Refund)
                              </div>
                            </td>
                            <template v-for="method in paymentMethods">
                              <td class="md-table-cell" :key="`${method.id}_m`">
                                <div
                                  class="md-table-cell-container text-center"
                                ></div>
                              </td>
                            </template>
                          </tr>
                          <tr
                            class="md-table-row grand_total_bg sticky_bottom"
                            :key="`order_${index}`"
                            v-else-if="cash.type == 'grandTotal'"
                          >
                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container text-center font-weight-black"
                              >
                                {{ cash.cashier }}
                              </div>
                            </td>
                            <template v-for="method in paymentMethods">
                              <td class="md-table-cell" :key="`${method.id}_m`">
                                <div
                                  class="md-table-cell-container text-center"
                                ></div>
                              </td>
                            </template>
                            <td class="md-table-cell">
                              <div
                                class="md-table-cell-container text-center"
                              ></div>
                            </td>
                            <td
                              class="md-table-cell"
                              :colspan="paymentMethods.length + 1"
                            >
                              <div
                                class="md-table-cell-container font-weight-black"
                              >
                                {{
                                  (cash.grandTotal
                                    ? parseFloat(cash.grandTotal)
                                    : 0) | toCurrency
                                }}
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="columns.length">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  components: { SvgIcon, BackButton, ExportIcon },
  data() {
    return {
      cashierReport: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        cashier_id: null,
        venueServiceId: null,
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
      product_categories: [],
      products: [],
      containerHeight: 400,
      columnData: [
        { name: "Cashier Name", value: "cashier" },
        { name: "Cash", value: "cash" },
        { name: "Card", value: "card" },
        { name: "Credit", value: "credit" },
        { name: "Voucher", value: "voucher" },
        { name: "Complementary", value: "complementary" },
      ],
      columns: [
        "cashier",
        "cash",
        "card",
        "credit",
        "voucher",
        "cheque",
        "wallet",
        "wire transfer",
        "complementary",
      ],
      paymentMethods: [],
      casherNames: [{ name: "All" }],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      sportsCategory: "getSportsService",
    }),
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },

    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  mounted() {
    this.onResize();
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
    this.getCasherName();
  },
  methods: {
    getTypeTotal(cash) {
      let total = 0;
      if (cash && cash.transaction_type != "count") {
        this.paymentMethods.forEach((item) => {
          if (item.name == "Online" && cash.transaction_type != "refund") {
            total = total + 0;
          } else {
            if (cash[item.name.replace(" ", "")] != undefined)
              total = total + parseFloat(cash[item.name.replace(" ", "")]);
          }
        });
      } else {
        return "-";
      }
      return parseFloat(total);
    },

    getTypeTotalTotal(cash) {
      let total = 0;
      if (cash && cash.transaction_type != "count") {
        this.paymentMethods.forEach((item) => {
          if (cash[item.name.replace(" ", "")] != undefined)
            total = total + parseFloat(cash[item.name.replace(" ", "")]);
        });
      } else {
        return "-";
      }
      return parseFloat(total);
    },
    checkTableColumnVisibility(column) {
      let index = this.columns.findIndex((item) => item == column);
      if (index == -1) {
        return false;
      }
      return true;
    },

    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },

    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      let productType = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }

      if (this.searchParams.productTypeId) {
        url += "&product_type_id=" + this.searchParams.productTypeId.id;
        productType = this.findValueByKey(
          this.productTypes,
          this.searchParams.productTypeId.id
        );
      }

      if (
        this.searchParams.cashier_id &&
        this.searchParams.cashier_id != "All" &&
        this.searchParams.cashier_id != "Online"
      ) {
        url += "&cashier_id=" + this.searchParams.cashier_id;
      }

      if (
        this.searchParams.cashier_id &&
        this.searchParams.cashier_id == "Online"
      ) {
        url += "&cashier_type=online";
      }

      if (productType !== null)
        this.fileName += "-" + productType.name.replace(/\s/g, "");
      if (venueService !== null)
        this.fileName += "-" + venueService.name.replace(/\s/g, "");
      if (this.fileName != null)
        this.fileName = "Cashier-Report-" + this.fileName;
      else this.fileName = "Cashier-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/cashier-sale-report${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.paymentMethods = data.payment_methods;
            let total = { type: "total", cashier: "Total" };
            // let refund = { type: "refund", cashier: "Refund Total" };
            let grandTotal = { type: "grandTotal", cashier: "Grand Total" };
            const cashierData = data.data;
            this.cashierReport = [];
            let allTotal = 0;
            // let refundAmount = 0;
            cashierData.forEach((log, index) => {
              var amount = {};
              var count = {};

              amount.type = "data";
              count.type = "data";
              let is_hide = true;
              for (let key in log) {
                if (
                  key == "transaction_type" ||
                  key == "order_date" ||
                  key == "cashier_id" ||
                  key == "cashier"
                ) {
                  amount[key] = log[key];
                  count[key] = log[key];
                } else if (key.includes("count")) {
                  count[key.split("_")[0]] = log[key];
                } else if (!key.includes("count")) {
                  amount[key] = log[key];
                  if (amount[key] != 0) {
                    is_hide = false;
                  }
                }
              }
              if (!is_hide) {
                if (index == 0) {
                  this.cashierReport.push({
                    order_date: log.order_date,
                    type: "date",
                  });
                }
                if (
                  index > 0 &&
                  cashierData[index - 1].order_date !=
                    cashierData[index].order_date
                ) {
                  this.cashierReport.push(total);
                  // this.cashierReport.push(refund);
                  //  this.cashierReport.push(grandTotal);
                  total = { type: "total", cashier: "Total" };
                  // refund = { type: "refund", cashier: "Refund Total" };
                  //  grandTotal = { type: "grandTotal", cashier: "Grand Total" };
                  this.cashierReport.push({
                    order_date: log.order_date,
                    type: "date",
                  });
                  //  allTotal = 0;
                  //     refundAmount = 0;
                }

                count.transaction_type = "count";

                this.cashierReport.push(amount);
                this.cashierReport.push(count);
                let allTypesTotal = 0;
                this.paymentMethods.forEach((item) => {
                  if (!total[item.name.replace(" ", "")])
                    total[item.name.replace(" ", "")] = 0;
                  if (item.name == "Online") {
                    if (log[item.name.replace(" ", "")] < 0)
                      total[item.name.replace(" ", "")] += log[
                        item.name.replace(" ", "")
                      ]
                        ? parseFloat(log[item.name.replace(" ", "")])
                        : 0;
                  } else {
                    total[item.name.replace(" ", "")] += log[
                      item.name.replace(" ", "")
                    ]
                      ? parseFloat(log[item.name.replace(" ", "")])
                      : 0;
                  }

                  if (
                    log[item.name.replace(" ", "")] != null &&
                    log[item.name.replace(" ", "")] != undefined &&
                    log[item.name.replace(" ", "")] != "" &&
                    item.name !== "Online"
                  ) {
                    allTypesTotal =
                      allTypesTotal +
                      parseFloat(log[item.name.replace(" ", "")]);
                  }
                  // if (
                  //   item.name.replace(" ", "") == "Online" &&
                  //   log.transaction_type == "refund"
                  // ) {
                  //   total["transaction_type"] = "refund";
                  // } else if (
                  //   item.name.replace(" ", "") == "Online" &&
                  //   log.transaction_type != "refund"
                  // ) {
                  //   total["transaction_type"] = "normal";
                  // }
                  // console.log("*******Item************");
                  // console.log("allTypesTotal");
                  // console.log(item.name);
                  // console.log(allTypesTotal);
                });
                allTotal = allTotal + allTypesTotal;
                console.log("*******TOTAL************");
                console.log("allTotal");
                console.log(allTotal);
              }
            });
            grandTotal["grandTotal"] = parseFloat(allTotal);
            console.log(allTotal);
            this.cashierReport.push(total);
            // this.cashierReport.push(refund);
            this.cashierReport.push(grandTotal);
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
          this.hideLoader();
        });
    },

    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "cashier-sale-report-pdf";
      } else if (type == "excel") {
        link = "cashier-sale-report";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `/download${url}&type=` + type, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getCasherName() {
      this.isLoading = true;
      this.$http
        .get(`venues/reports/cashier-names`)
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            this.casherNames = response.data.data;
            this.casherNames.unshift({ name: "All" });
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    onResize() {
      this.containerHeight = window.innerHeight - 360;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #edf7f8; /* Set color opacity to 10% */
  padding: 10px 4px;
  z-index: 5;
}
.md-table table {
  overflow: scroll;
}
.md-card .md-card-header {
  z-index: 1000;
}
</style>
